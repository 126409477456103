<template>
  <div class="archived-posts-page">
    <div
      v-if="isInfoActive"
      class="archive-info-panel w-100 d-flex align-items-center justify-content-between rounded mb-1"
    >
      <div class="d-flex align-items-center">
        <img
          :src="informationIcon"
          alt="action"
          class="p-0"
        >
        <h5 class="mb-0">
          {{ $t('Only you can see archived posts.') }}
        </h5>
      </div>
      <img
        :src="cancelIcon"
        alt="action"
        class="p-0 cursor-pointer"
        @click="isInfoActive = false"
      >
    </div>
    <div
      class="
          archived-posts-controll-bar
          w-100
          d-flex
          align-items-center
          justify-content-between
          p-1
          bg-white
          mb-2
          rounded"
    >
      <div>
        <b-form-checkbox
          v-model="check_all"
          class="font-weight-bold"
          @change="selectAllPostsAction"
        >{{ $t('All') }}</b-form-checkbox>
      </div>
      <div>
        <b-button
          class="font-weight-bold"
          variant="outline-primary"
          :disabled="!selected_posts.length"
          @click="selectedPostsActions('unarchive')"
        >{{ $t('Unarchive') }}</b-button>
        <b-button
          class="font-weight-bold ml-1"
          variant="outline-primary"
          :disabled="!selected_posts.length"
          @click="selectedPostsActions('delete')"
        >{{ $t('Delete') }}</b-button>
      </div>
    </div>
    <archived-post
      v-for="post in posts"
      :key="post.slug"
      :post="post"
      :check-all="check_all"
      :selected-posts="selected_posts"
      @selectPost="selectPost"
      @deselectPost="deselectPost"
      @unarchivePost="unarchivePost"
      @deletePost="deletePost"
    />
  </div>
</template>

<script>

import { BButton, BFormCheckbox } from 'bootstrap-vue'

import informationIcon from '@/assets/images/pages/profile-company/information-icon.svg'
import cancelIcon from '@/assets/images/pages/profile-company/cancel-icon.svg'

import ArchivedPost from '@/components/profile-company/ArchivedPosts/ArchivedPost.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormCheckbox,

    ArchivedPost,
  },
  data() {
    return {
      informationIcon,
      cancelIcon,

      check_all: false,
      isInfoActive: true,
      selected_posts: [],
    }
  },
  computed: {
    posts() {
      return this.$store.getters['profileCompany/archivedPosts']
    },
  },
  created() {
    this.fetchPosts()
  },
  methods: {
    async fetchPosts() {
      await this.$store.dispatch('profileCompany/fetchPosts', { slug: this.$route.params.slug, archive: true })
    },
    selectPost(slug) {
      this.selected_posts.push(slug)
    },
    deselectPost(slug) {
      const index = this.selected_posts.indexOf(slug)
      if (index > -1) {
        this.selected_posts.splice(index, 1)
      }
    },
    selectAllPostsAction() {
      if (this.selected_posts.length === this.posts.length) {
        this.selected_posts = []
        return
      }
      this.selected_posts = []
      this.posts.forEach(post => {
        this.selected_posts.push(post.slug)
      })
    },
    async unarchivePost(post) {
      this.$store.dispatch('posts/unarchivePostById', { slug: post.slug, owner: post.owner.type }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Unarchived'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        const postIndex = this.posts.findIndex(el => el.slug === post.slug)
        this.posts.splice(postIndex, 1)
      })
    },
    async deletePost(post) {
      this.$store.dispatch('posts/deletePostById', { slug: post.slug, owner: post.owner.type }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Deleted'),
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        const postIndex = this.posts.findIndex(el => el.slug === post.slug)
        this.posts.splice(postIndex, 1)
      })
    },
    selectedPostsActions(action) {
      const array = this.posts.filter(post => this.selected_posts.includes(post.slug))
      array.forEach(async post => {
        if (action === 'unarchive') await this.unarchivePost(post)
        else await this.deletePost(post)
      })
      this.selected_posts = []
    },
  },
}
</script>
