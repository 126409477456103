<template>
  <div
    class="archived-post
        rounded
        w-100
        d-flex
        align-items-center
        justify-content-between
        bg-white
        p-1
        cursor-pointer
        mb-1
        "
    @click.self="showPostPreview"
  >
    <div class="d-flex align-items-center">
      <div class="position-relative">
        <b-form-checkbox
          :ref="`checkbox-${post.slug}`"
          :v-model="check"
          class="mr-1"
          @change="checkBoxAction"
        />
      </div>
      <div
        class="d-flex align-items-center cursor-pointer"
        @click="showPostPreview"
      >
        <img
          :src="post.owner.image ? post.owner.image : '@/assets/images/pages/user-placeholder.svg'"
          alt="image"
          class="rounded-circle mr-1 post-image"
        >
        <div>
          <h5 class="mb-0 font-weight-bold">
            {{ post.title }}
          </h5>
          <h6
            class="text-muted mb-0"
          >{{ $t('Archived on') }} {{ post.edited | formatDate }}</h6>
        </div>
      </div>
    </div>
    <div class="actions-btn-container">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        right
      >
        <template #button-content>
          <img
            :src="actionImg"
            alt="action"
            class="p-0"
          >
        </template>
        <b-dropdown-item @click="unarchivePost">
          <img
            :src="unarchiveImg"
            alt="unarchive"
            class="dropdown-action-icon"
          >
          {{ $t('Unarchive') }}
        </b-dropdown-item>
        <b-dropdown-item @click="deletePost">
          <img
            :src="deleteImg"
            alt="delete"
            class="dropdown-action-icon"
          >
          {{ $t('Delete') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <preview-post :post="post" />
  </div>
</template>

<script>

import { BFormCheckbox, BDropdown, BDropdownItem } from 'bootstrap-vue'

import actionImg from '@/assets/images/pages/profile-company/action.svg'
import deleteImg from '@/assets/images/pages/profile-company/trash.svg'
import unarchiveImg from '@/assets/images/pages/profile-company/un-archive-icon.svg'

import PreviewPost from '@/components/profile-company/ArchivedPosts/PreviewPost.vue'
import moment from 'moment'

export default {
  components: {
    BFormCheckbox,
    BDropdown,
    BDropdownItem,

    PreviewPost,
  },
  filters: {
    formatDate(date) {
      return moment(date)
        .format('DD MMMM YYYY, hh:mm')
    },
  },
  props: {
    checkAll: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: true,
    },
    selectedPosts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      actionImg,
      deleteImg,
      unarchiveImg,

      check: false,
    }
  },
  watch: {
    checkAll(value) {
      this.check = value
      const myCheckbox = this.$refs[`checkbox-${this.post.slug}`]
      myCheckbox.localChecked = value
    },
  },
  methods: {
    showPostPreview() {
      EventBus.$emit(`showArchivedPostPreview-${this.post.slug}`)
    },
    checkBoxAction() {
      if (this.checkAll) {
        this.$emit('selectPost', this.post.slug)
        this.check = true
        return
      }
      if (this.check) {
        this.$emit('deselectPost', this.post.slug)
        this.check = false
      } else {
        this.$emit('selectPost', this.post.slug)
        this.check = true
      }
    },
    unarchivePost() {
      this.$emit('unarchivePost', this.post)
    },
    deletePost() {
      this.$emit('deletePost', this.post)
    },
  },
}
</script>
