<template>
  <b-modal
    :id="`modal-arcived-post-preview-${post.slug}`"
    centered
    hide-footer
  >
    <template #modal-title>
      <div class="d-flex modal-arcived-post-preview-title">
        <div class="d-flex align-items-center">
          <img
            class="rounded-circle avatar-logo"
            alt="Logo post placeholder"
            :src="getLogo"
            @click="goToOwnerPage"
          >
          <a
            class="e-post-author__title"
            href="#"
            @click="goToOwnerPage"
          >
            {{ post.owner.name }}
          </a>
          <img
            :src="verticalSmallLine"
            alt="Vertical line | small"
          >
          <span class="text-muted">{{ post.date_created | formatDate }}</span>
        </div>
      </div>
    </template>
    <div class="post-preview-body">
      <h3 class="font-weight-bold">
        {{ post.title }}
      </h3>
      <post-description
        :content="post.text"
        :max-length="400"
      />
      <div
        v-if="post.image"
      >
        <img
          class="w-100 rounded mt-1"
          :src="post.image"
          alt="Placeholder post image"
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
} from 'bootstrap-vue'

import verticalSmallLine from '@/assets/images/pages/profile-company/vertical-small-line.svg'
import avatarPlaceholder from '@/assets/images/pages/profile/user-default-avatar.svg'

import PostDescription from '@/components/profile-company/Post/PostDescription.vue'
import moment from 'moment'

export default {
  components: {
    BModal,

    PostDescription,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    formatDate(date) {
      return moment(date)
        .format('DD MMMM YYYY, hh:mm')
    },
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      verticalSmallLine,
      avatarPlaceholder,
    }
  },
  computed: {
    getLogo() {
      return this.post.owner.image || this.avatarPlaceholder
    },
  },
  created() {
    EventBus.$on(`showArchivedPostPreview-${this.post.slug}`, () => {
      this.$root.$emit('bv::show::modal', `modal-arcived-post-preview-${this.post.slug}`, '#focusThisOnClose')
    })
  },
  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'modal-arcived-post-preview', '#focusThisOnClose')
    },
    goToOwnerPage() {
      if (this.post.owner.type === 'company') {
        this.$router.push(`/profile_company/${this.post.owner.slug}`)
      } else {
        this.$router.push(`/profile/${this.post.owner.slug}`)
      }
    },
  },
}
</script>
